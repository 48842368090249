import getCheckoutData from 'utils/getCheckoutData';

const sendPaymentStartedEvent = (selectedPanelType, amountOfPanels) => {
  if (typeof window === 'undefined') {
    return;
  }

  window.dataLayer.push({
    event: 'payment_started',
    price: getCheckoutData(selectedPanelType, amountOfPanels),
  });
};

export default sendPaymentStartedEvent;
