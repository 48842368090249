import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';

import config from 'config';

import './ToDownload.scss';

const ToDownload = ({ files, onlyFiles, inline }) => {
  const intl = useIntl();

  return (
    <div className="to-download">
      {
        !onlyFiles && (
          <>
            <div className="bg-image">
              <img src="/images/8947_detal_s.png" alt="" className="img-responsive" />
            </div>
            <h2>
              <FormattedMessage id="toDownloadTitle" />
            </h2>
          </>
        )
      }
      <div className={classNames('files-wrapper', {
        'inline mobile-inline': inline,
        'full-width': onlyFiles,
      })}
      >
        {
          files.map((el) => (el.lang === intl.locale || el.lang === 'all' ? (
            <div key={el.id}>
              <div
                className="file-wrapper"
              >
                <a href={el.src} target="_blank" rel="noreferrer">
                  <FormattedMessage id={el.descId} />
                </a>
              </div>
            </div>
          ) : null))
        }
      </div>
      {
        !onlyFiles && (
          <div className="contact-wrapper">
            <div className="line" />
            <h2>
              <FormattedMessage id="toDownloadContact" />
            </h2>
            <div className="description-text">
              <FormattedMessage id="toDownloadContactDescription" />
            </div>
            <div className="contact-data inline mobile-inline">
              <div className="contact-item left">
                {config.supportAddress.street}
                <br />
                {config.supportAddress.city}
              </div>
              <div className="contact-item right">
                {config.supportContact.mail}
              </div>
            </div>
            <div className="bottom-line-wrapper">
              <div className="line" />
            </div>
          </div>
        )
      }
    </div>
  );
};

ToDownload.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    descId: PropTypes.string,
    src: PropTypes.string,
    lang: PropTypes.string,
  })),
  inline: PropTypes.bool,
  onlyFiles: PropTypes.bool,
};

ToDownload.defaultProps = {
  files: [],
  inline: true,
  onlyFiles: false,
};

export default ToDownload;
