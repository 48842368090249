import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';

export default (panels) => {
  let count = 0;

  _forEach(panels, (el) => {
    const selected = _filter(el, (el1) => el1.selected);
    count += selected.length;
  });

  return count;
};
