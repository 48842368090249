import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import config from 'config';

const panelsConfiguration = [
  [0, 0, 0],
  [0, 0, 0],
  [0, 0, 0],
  [0, 0, 0],
];

const generateSaveFormData = (panels, selectedPanelType, userData, paymentData) => {
  const panel = _find(config.panelTypes, { id: selectedPanelType });
  const panelId = panel.panelId;
  const newConfig = [...panelsConfiguration];

  _forEach(panels, (row, rowIndex) => {
    _forEach(row, (column, columnIndex) => {
      newConfig[columnIndex][rowIndex] = column.selected ? 1 : 0;
    });
  });

  return {
    panelType: panelId,
    configuration: newConfig,
    userData,
    paymentData: {
      orderId: paymentData.orderID,
      payerId: paymentData.payerID,
    },
  };
};

export default generateSaveFormData;
