import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'gatsby-plugin-intl';

import './CheckoutHeader.scss';

const steps = [{
  id: 'address',
  descId: 'checkoutHeaderStep1',
}, {
  id: 'payment',
  descId: 'checkoutHeaderStep3',
}];

const CheckoutHeader = ({ currentStep }) => (
  <div className="checkout-header">
    <div className="line" />
    <div className="steps-wrapper">
      {
        steps.map((el, key) => (
          <React.Fragment key={`checkout-header-step-${el.id}`}>
            <div
              className={classNames('step', {
                active: key <= currentStep,
              })}
            >
              <span>
                {key + 1}
                .&nbsp;
              </span>
              <FormattedMessage id={el.descId} />
            </div>
            {
                key < steps.length - 1 && (
                  <div className="long-line" />
                )
            }
          </React.Fragment>
        ))
      }
    </div>
  </div>
);

CheckoutHeader.propTypes = {
  currentStep: PropTypes.number,
};

CheckoutHeader.defaultProps = {
  currentStep: 0,
};

export default CheckoutHeader;
