import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { navigate, useIntl } from 'gatsby-plugin-intl';
import _forEach from 'lodash/forEach';

import { PayPalButtons } from '@paypal/react-paypal-js';
import { useSnackbar } from 'react-simple-snackbar';

import config from 'config';

import OrderSpecs from 'components/OrderSpecs';
import CheckoutHeader from 'components/CheckoutHeader';
import getData from 'utils/getData';
import saveData from 'utils/saveData';
import countPanels from 'utils/countPanels';
import generateSaveFormData from 'utils/generateSaveFormData';
import getPrice from 'utils/getPrice';
import getDeliveryPrice from 'utils/getDeliveryPrice';
import sendPaymentStartedEvent from 'utils/sendPaymentStartedEvent';

import './ConfiguratorPageStep5.scss';

const ConfiguratorPageStep5 = ({ typeId }) => {
  const intl = useIntl();
  const [openSnackbar] = useSnackbar();
  const [selectedPanelType, setSelectedPanelType] = useState(typeId || config.panelTypes[0].id);
  const panels = getData('configuration');
  const userData = getData('checkout-step-3');
  const amountOfPanels = countPanels(panels);
  const price = getPrice(amountOfPanels, selectedPanelType) + getDeliveryPrice(amountOfPanels);

  const createOrder = (data, actions) => actions.order.create({
    purchase_units: [
      {
        amount: {
          value: price,
        },
      },
    ],
  });

  const onApprove = (paymentData) => {
    const data = generateSaveFormData(panels, selectedPanelType, userData, paymentData);
    data.lang = intl.locale;

    if (typeof window !== 'undefined') {
      const formData = new window.FormData();

      _forEach(data, (el, key) => {
        formData.append(key, JSON.stringify(el));
      });

      window.fetch('/saveform', {
        method: 'post',
        body: formData,
      }).then(() => {
        saveData('checkout-step-4', {});
        saveData('checkout-step-3', {});
        saveData('configuration', {});
        navigate(
          '/configurator/order-approved',
          {
            state: {
              price,
              amountOfPanels,
              paymentData,
              selectedPanelType,
            },
          },
        );
      }).catch(() => {
        openSnackbar(intl.formatMessage({ id: 'configuratorSaveFormError' }));
      });
    }
  };

  const onError = () => {
    openSnackbar(intl.formatMessage({ id: 'configuratorPaypalPaymentError' }));
  };

  return (
    <div className="configurator-page-step-5">
      <div className="two-columns inline">
        <div className="left">
          <OrderSpecs
            selectedPanelType={selectedPanelType}
            setSelectedPanelType={setSelectedPanelType}
            amountOfPanels={amountOfPanels}
            hideChange
          />
        </div>
        <div className="right">
          <CheckoutHeader
            currentStep={2}
          />
          <div className="paypal-buttons-wrapper">
            <PayPalButtons
              createOrder={createOrder}
              onApprove={onApprove}
              onError={onError}
              onCancel={onError}
              onClick={() => {
                sendPaymentStartedEvent(selectedPanelType, amountOfPanels);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ConfiguratorPageStep5.propTypes = {
  typeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ConfiguratorPageStep5.defaultProps = {
  typeId: null,
};

export default ConfiguratorPageStep5;
