import getDeliveryPrice from 'utils/getDeliveryPrice';
import getPrice from 'utils/getPrice';

const getCheckoutData = (selectedPanelType, amountOfPanels) => {
  const price = getPrice(amountOfPanels, selectedPanelType) + getDeliveryPrice(amountOfPanels);

  return price;
};

export default getCheckoutData;
