import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import ConfiguratorPageStep5 from 'components/ConfiguratorPageStep5';

const ConfiguratorStep5 = ({ location }) => {
  const typeId = _get(location, 'state.typeId', null);

  return (
    <ConfiguratorPageStep5 typeId={typeId} />
  );
};

ConfiguratorStep5.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      typeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
};

export default ConfiguratorStep5;
