import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'gatsby-plugin-intl';
import _find from 'lodash/find';
import _map from 'lodash/map';

import getPrice from 'utils/getPrice';
import getDeliveryPrice from 'utils/getDeliveryPrice';
import getGroupedPanels from 'utils/getGroupedPanels';

import config from 'config';

import ToDownload from 'components/ToDownload';

import './OrderSpecs.scss';

const files = [{
  id: 'product-specs-download-1',
  descId: 'orderSpecsProductCart',
  src: '',
  lang: 'all',
}, {
  id: 'product-specs-download-3',
  descId: 'guide2x2',
  src: '/files/guide_2x2.pdf',
  lang: 'all',
}, {
  id: 'product-specs-download-4',
  descId: 'guide3x1',
  src: '/files/guide_3x1.pdf',
  lang: 'all',
}, {
  id: 'product-specs-download-5',
  descId: 'guide2x1',
  src: '/files/guide_2x1.pdf',
  lang: 'all',
}];

const OrderSpecs = ({
  selectedPanelType, setSelectedPanelType, amountOfPanels, hideChange,
}) => {
  const [changingPanelType, setChangingPanelType] = useState(false);
  const selectedPanel = _find(config.panelTypes, { id: selectedPanelType });
  const deliveryPrice = getDeliveryPrice(amountOfPanels);

  return (
    <div className="order-specs">
      <div>
        <div className="line" />
      </div>
      <h1 className="right-padding">
        <FormattedMessage id="orderSpecsTitle" />
      </h1>
      <div className="price always-inline">
        <span className="label">
          <FormattedMessage id="orderSpecsPrice" />
        </span>
        <div className="bold">
          {getPrice(amountOfPanels, selectedPanelType)}
          <span className="left-pad">
            <FormattedMessage id="orderSpecsCurrency" />
          </span>
        </div>
      </div>
      <div className="panel-type always-inline">
        <span className="label">
          <FormattedMessage id="orderSpecsChosen" />
        </span>
        <div className="bold">
          {selectedPanel.name}
        </div>
        {
            !hideChange && !changingPanelType && (
              <div className="button">
                <a
                  onClick={() => {
                    setChangingPanelType(true);
                  }}
                >
                  <FormattedMessage id="orderSpecsChange" />
                </a>
              </div>
            )
        }
      </div>
      <div
        className={classNames('change-panel-type-wrapper', {
          hidden: !changingPanelType,
        })}
      >
        {
          _map(getGroupedPanels(config.panelTypes), (el, key) => (
            <div key={key} className="group-wrapper">
              <div className="group-name">
                {key}
              </div>
              <div className="items">
                {
                  _map(el, (el1) => (
                    <div
                      key={el1.id}
                      className="panel-type-image"
                      onClick={() => {
                        setSelectedPanelType(el1.id);
                        setChangingPanelType(false);
                      }}
                    >
                      <img src={`/images/${el1.image}`} alt="" className="img-responsive" />
                    </div>
                  ))
                }
              </div>
            </div>
          ))
        }
      </div>
      <div className="panel-amount always-inline">
        <span className="label">
          <FormattedMessage id="orderSpecsAmount" />
        </span>
        <div className="bold">
          {amountOfPanels}
          <span className="left-pad">
            <FormattedMessage id="orderSpecsPieces" />
          </span>
        </div>
      </div>
      <div className="delivery-price always-inline">
        <span className="label">
          <FormattedMessage id="dhlDelivery" />
        </span>
        <div className="bold">
          {
            deliveryPrice ? (
              <>
                {deliveryPrice}
                <span className="left-pad">
                  {config.currency}
                </span>
              </>
            ) : (
              <FormattedMessage id="forFree" />
            )
          }
        </div>
      </div>
      <div className="realization-time always-inline">
        <span className="label">
          <FormattedMessage id="realizationLabel" />
        </span>
        <div className="bold">
          {config.realizationDays}
          <span className="left-pad">
            <FormattedMessage id="realizationDays" />
          </span>
        </div>
      </div>
      <div className="to-download">
        <h1>
          <FormattedMessage id="orderSpecsToDownload" />
        </h1>
        <ToDownload
          files={files}
          inline={false}
          onlyFiles
        />
      </div>
    </div>
  );
};

OrderSpecs.propTypes = {
  amountOfPanels: PropTypes.number.isRequired,
  selectedPanelType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setSelectedPanelType: PropTypes.func.isRequired,
  hideChange: PropTypes.bool,
};

OrderSpecs.defaultProps = {
  hideChange: false,
};

export default OrderSpecs;
