import _find from 'lodash/find';

import config from 'config';

export default (numberOfPanels, panelType) => {
  const selectedPanel = _find(config.panelTypes, { id: panelType });
  const priceRange = _find(
    selectedPanel.prices,
    (el) => el.min <= numberOfPanels && el.max >= numberOfPanels,
  );

  return priceRange ? numberOfPanels * priceRange.price : null;
};
